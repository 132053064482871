/* @import "animations/anim-styles.css"; */
* {
   /* -webkit-touch-callout: none; iOS Safari */ 
   /* -webkit-user-select: none; Safari */
   /* -khtml-user-select: none; Konqueror HTML */
   /* -moz-user-select: none; Old versions of Firefox */
   /* -ms-user-select: none; Internet Explorer/Edge */
   /* user-select: none; Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
   
   /* -webkit-touch-callout: none; 
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none; 
   -ms-user-select: none;
   user-select: none;  */
}

html,
body {
   scroll-behavior: smooth;
}

body {
   overflow-x: hidden;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type="number"] {
   -moz-appearance: textfield;
}

.bluedotlist {
   /* list-style: url("assets/icons/bluedot.png"); */
   list-style-position: inside;
}

.socials {
   display: flex;
   align-items: center;
}

.socials > * {
   margin-left: 1.5em;
}

button.MuiBox-root::before {
   background-color: #7211d4;
}

@media screen and (max-width: 768px) {
   .tabs-box {
      display: none;
   }
}
