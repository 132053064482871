*, *::after, *::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

a {
  text-decoration: none; }

ul, ol {
  list-style-type: none; }

.pricing-sass {
  width: 100%;
  margin-top: 5rem; }
  .pricing-sass .pricing-sass_top {
    width: 100%;
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding-top: 64.3px;
    flex-direction: column;
    background: #F6F6FB;
    position: relative; }
    @media screen and (max-width: 1180px) {
      .pricing-sass .pricing-sass_top {
        padding: 0 40px;
        padding-top: 64.3px; } }
    @media screen and (max-width: 642px) {
      .pricing-sass .pricing-sass_top {
        padding: 0 20px;
        padding-top: 64.3px; } }
    .pricing-sass .pricing-sass_top span.ball {
      position: absolute;
      border-radius: 50%;
      display: inline-block; }
      .pricing-sass .pricing-sass_top span.ball.second {
        width: 44.9px;
        height: 44.9px;
        right: 177.36px;
        top: 64.34px;
        background: linear-gradient(151.25deg, #FB9B54 5.51%, rgba(255, 255, 255, 0) 69.14%);
        transform: rotate(47.45deg); }
        @media screen and (max-width: 512px) {
          .pricing-sass .pricing-sass_top span.ball.second {
            right: 20.36px; } }
      .pricing-sass .pricing-sass_top span.ball.first {
        width: 48.3px;
        height: 48.3px;
        left: 211.45px;
        top: 35.07px;
        background: linear-gradient(151.25deg, #4665A8 5.51%, rgba(255, 255, 255, 0) 69.14%);
        transform: matrix(0.96, 0.29, 0.29, -0.96, 0, 0); }
        @media screen and (max-width: 1176px) {
          .pricing-sass .pricing-sass_top span.ball.first {
            top: 85.07px; } }
        @media screen and (max-width: 760px) {
          .pricing-sass .pricing-sass_top span.ball.first {
            left: 111.45px; } }
        @media screen and (max-width: 512px) {
          .pricing-sass .pricing-sass_top span.ball.first {
            left: 20px;
            top: 64px; } }
      .pricing-sass .pricing-sass_top span.ball.third {
        width: 29.9px;
        height: 29.9px;
        left: 179.03px;
        top: 164.15px;
        background: linear-gradient(151.25deg, #7211D4 5.51%, rgba(255, 255, 255, 0) 69.14%);
        transform: matrix(0.96, 0.29, 0.29, -0.96, 0, 0); }
        @media screen and (max-width: 1176px) {
          .pricing-sass .pricing-sass_top span.ball.third {
            top: 184px; } }
        @media screen and (max-width: 512px) {
          .pricing-sass .pricing-sass_top span.ball.third {
            left: 26px; } }
      .pricing-sass .pricing-sass_top span.ball.fourth {
        width: 28.43px;
        height: 28.43px;
        right: 285.01px;
        top: 162.79px;
        background: linear-gradient(151.25deg, #C44CC0 5.51%, rgba(255, 255, 255, 0) 69.14%);
        transform: rotate(47.45deg); }
        @media screen and (max-width: 760px) {
          .pricing-sass .pricing-sass_top span.ball.fourth {
            right: 85.01px;
            top: 182.79px; } }
    .pricing-sass .pricing-sass_top > h1 {
      max-width: 683px;
      width: 100%;
      font-family: 'helvetica, sans-serif';
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 130%;
      text-align: center;
      text-transform: capitalize;
      color: #020202;
      margin-bottom: 9.49px;
      position: relative;
      z-index: 2; }
      @media screen and (max-width: 760px) {
        .pricing-sass .pricing-sass_top > h1 {
          font-size: 30px;
          font-weight: 600; } }
    .pricing-sass .pricing-sass_top p {
      max-width: 534.14px;
      width: 100%;
      font-family: 'helvetica, sans-serif';
      font-style: normal;
      font-weight: 325;
      font-size: 14px;
      line-height: 25px;
      text-align: center;
      color: #000000;
      margin-bottom: 146.55px;
      position: relative;
      z-index: 2; }
  .pricing-sass .starter {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin-top: 61.23px; }
    @media screen and (max-width: 1148px) {
      .pricing-sass .starter {
        padding: 0 40px; } }
    @media screen and (max-width: 642px) {
      .pricing-sass .starter {
        padding: 0 20px; } }
    .pricing-sass .starter .starter_wrapper {
      box-sizing: border-box;
      max-width: 1107.71px;
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #7211D4;
      box-shadow: 0px 6px 30px rgba(51, 51, 51, 0.06), inset 0px -11.749px 11.749px #F2F2F2, inset 0px 11.749px 11.749px #FFFFFF;
      border-radius: 24px;
      padding: 53.88px 54.12px 0 55.1px;
      display: flex;
      justify-content: space-between;
      align-items: stretch; }
      @media screen and (max-width: 1037px) {
        .pricing-sass .starter .starter_wrapper {
          padding: 53.88px 44.12px 0 45.1px; } }
      @media screen and (max-width: 990px) {
        .pricing-sass .starter .starter_wrapper {
          padding: 53.88px 30px 0 30px; } }
      @media screen and (max-width: 959px) {
        .pricing-sass .starter .starter_wrapper {
          flex-direction: column;
          padding: 53.88px 64.12px 0 65.1px; } }
      @media screen and (max-width: 758px) {
        .pricing-sass .starter .starter_wrapper {
          display: flex;
          justify-content: stretch;
          align-items: flex-start;
          padding: 53.88px 34.12px 0 35.1px; } }
      .pricing-sass .starter .starter_wrapper .starter_left > h1 {
        font-family: 'helvetica, sans-serif';
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 34px;
        letter-spacing: 0.02em;
        color: #000000;
        margin-bottom: 28.35px; }
      .pricing-sass .starter .starter_wrapper .starter_left h2.included {
        font-family: 'helvetica, sans-serif';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 34px;
        color: #7211D4;
        white-space: nowrap;
        display: flex;
        justify-content: stretch;
        align-items: center;
        margin-bottom: 22.25px; }
        .pricing-sass .starter .starter_wrapper .starter_left h2.included span {
          display: inline-block;
          max-width: 415.76px;
          width: 100%;
          height: 1px;
          background: #C4C4C4;
          margin-left: 18.33px; }
          @media screen and (max-width: 1096px) {
            .pricing-sass .starter .starter_wrapper .starter_left h2.included span {
              max-width: 335.76px; } }
          @media screen and (max-width: 959px) {
            .pricing-sass .starter .starter_wrapper .starter_left h2.included span {
              max-width: 100%;
              width: 100%; } }
      .pricing-sass .starter .starter_wrapper .starter_left .starter_left_details ul {
        max-width: 535.76px;
        width: 100%;
        display: flex;
        justify-content: "stretch";
        align-items: "stretch";
        flex-wrap: wrap; }
        @media screen and (max-width: 959px) {
          .pricing-sass .starter .starter_wrapper .starter_left .starter_left_details ul {
            max-width: 100%;
            width: 100%; } }
        .pricing-sass .starter .starter_wrapper .starter_left .starter_left_details ul li {
          flex: 50%;
          display: flex;
          justify-content: stretch;
          align-items: center;
          margin-bottom: 40.62px; }
          @media screen and (max-width: 568px) {
            .pricing-sass .starter .starter_wrapper .starter_left .starter_left_details ul li {
              margin-bottom: 30.62px; } }
          @media screen and (max-width: 508px) {
            .pricing-sass .starter .starter_wrapper .starter_left .starter_left_details ul li {
              flex: 100%; } }
          .pricing-sass .starter .starter_wrapper .starter_left .starter_left_details ul li:last-child {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'helvetica, sans-serif';
            font-style: normal;
            font-weight: 350;
            font-size: 14px;
            line-height: 160%;
            letter-spacing: -0.02em;
            color: #7211D4; }
            .pricing-sass .starter .starter_wrapper .starter_left .starter_left_details ul li:last-child span.link {
              margin-right: 10px; }
          .pricing-sass .starter .starter_wrapper .starter_left .starter_left_details ul li span.dot {
            display: inline-block;
            width: 17.56px;
            height: 17.05px;
            border-radius: 50%;
            background: #000000;
            margin-right: 16.01px; }
            @media screen and (max-width: 568px) {
              .pricing-sass .starter .starter_wrapper .starter_left .starter_left_details ul li span.dot {
                margin-right: 8px;
                width: 14.56px;
                height: 14.56px; } }
          .pricing-sass .starter .starter_wrapper .starter_left .starter_left_details ul li span.text {
            font-family: 'helvetica, sans-serif';
            font-style: normal;
            font-weight: 325;
            font-size: 14px;
            line-height: 160%;
            color: #000000;
            display: inline-block;
            border-bottom: 1px dashed #C4C4C4; }
            @media screen and (max-width: 568px) {
              .pricing-sass .starter .starter_wrapper .starter_left .starter_left_details ul li span.text {
                font-size: 12px; } }
      .pricing-sass .starter .starter_wrapper .starter_right {
        display: flex;
        justify-content: stretch;
        align-items: center;
        flex-direction: column; }
        @media screen and (max-width: 959px) {
          .pricing-sass .starter .starter_wrapper .starter_right {
            display: flex;
            justify-content: stretch;
            align-items: flex-start;
            padding: 40px 0;
            border-top: 1px solid rgba(236, 230, 230, 0.829); } }
        @media screen and (max-width: 568px) {
          .pricing-sass .starter .starter_wrapper .starter_right {
            display: flex;
            justify-content: stretch;
            align-items: center; } }
        .pricing-sass .starter .starter_wrapper .starter_right h2 {
          max-width: 318px;
          width: 100%;
          font-family: 'helvetica, sans-serif';
          font-style: normal;
          font-weight: 350;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          color: #000000;
          margin-bottom: 29.07px; }
          @media screen and (max-width: 959px) {
            .pricing-sass .starter .starter_wrapper .starter_right h2 {
              max-width: 618px;
              text-align: left;
              margin-bottom: 19.07px; } }
          @media screen and (max-width: 568px) {
            .pricing-sass .starter .starter_wrapper .starter_right h2 {
              text-align: center; } }
        .pricing-sass .starter .starter_wrapper .starter_right h3 {
          font-family: 'helvetica, sans-serif';
          font-style: normal;
          font-weight: 700;
          font-size: 48px;
          line-height: 160%;
          color: #000000;
          margin-bottom: 6.77rem; }
          @media screen and (max-width: 959px) {
            .pricing-sass .starter .starter_wrapper .starter_right h3 {
              margin-bottom: 27.7px; } }
          .pricing-sass .starter .starter_wrapper .starter_right h3 span {
            font-weight: 325;
            font-size: 16px;
            line-height: 20px; }
        .pricing-sass .starter .starter_wrapper .starter_right button {
          max-width: 339.91px;
          width: 100%;
          height: 58.29px;
          background: #7211D4;
          border-radius: 10px;
          border: none;
          font-family: 'helvetica, sans-serif';
          font-style: normal;
          font-weight: 350;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: capitalize;
          color: #FFFFFF; }
          @media screen and (max-width: 568px) {
            .pricing-sass .starter .starter_wrapper .starter_right button {
              max-width: 100%; } }
  .pricing-sass .maximize_header {
    width: 100%;
    display: flex;
    justify-content: stretch;
    align-items: center;
    flex-direction: column;
    margin-top: 136.93px; }
    @media screen and (max-width: 1148px) {
      .pricing-sass .maximize_header {
        padding: 0 40px; } }
    @media screen and (max-width: 642px) {
      .pricing-sass .maximize_header {
        padding: 0 20px; } }
    .pricing-sass .maximize_header > h2 {
      font-family: 'helvetica, sans-serif';
      font-style: normal;
      font-weight: 350;
      font-size: 14px;
      line-height: 160%;
      text-transform: uppercase;
      color: #850FD9;
      margin-bottom: 9.16px; }
      @media screen and (max-width: 552px) {
        .pricing-sass .maximize_header > h2 {
          font-size: 12px; } }
    .pricing-sass .maximize_header h1 {
      font-family: 'helvetica, sans-serif';
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 140%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #000000;
      margin-bottom: 96.68px; }
      @media screen and (max-width: 862px) {
        .pricing-sass .maximize_header h1 {
          max-width: 600px;
          width: 100%; } }
      @media screen and (max-width: 552px) {
        .pricing-sass .maximize_header h1 {
          font-size: 26px;
          margin-bottom: 46.68px; } }
    .pricing-sass .maximize_header > .maximize_header_feature {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1063.56px;
      width: 100%;
      height: 74.11px;
      background: #F6F6FB;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      padding: 0 119px;
      margin-bottom: 90.68px; }
      @media screen and (max-width: 862px) {
        .pricing-sass .maximize_header > .maximize_header_feature {
          padding: 0 40px; } }
      .pricing-sass .maximize_header > .maximize_header_feature p {
        font-family: 'helvetica, sans-serif';
        font-style: normal;
        font-weight: 350;
        font-size: 20px;
        line-height: 160%;
        text-transform: uppercase; }
        @media screen and (max-width: 552px) {
          .pricing-sass .maximize_header > .maximize_header_feature p {
            font-size: 14px; } }
  .pricing-sass .pricing_feature-comp {
    display: flex;
    justify-content: stretch;
    align-items: center;
    flex-direction: column; }
    @media screen and (max-width: 1148px) {
      .pricing-sass .pricing_feature-comp {
        margin-left: 40px;
        margin-right: 40px; } }
    @media screen and (max-width: 642px) {
      .pricing-sass .pricing_feature-comp {
        margin-left: 20px;
        margin-right: 20px; } }
    .pricing-sass .pricing_feature-comp h1 {
      font-family: 'helvetica, sans-serif';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 160%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #000000;
      margin-bottom: 24px;
      text-transform: capitalize; }
    .pricing-sass .pricing_feature-comp > ul {
      max-width: 1071.06px;
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #7211D4;
      box-shadow: inset -7.19418e-16px -11.749px 11.749px #F2F2F2;
      border-radius: 24px; }
      .pricing-sass .pricing_feature-comp > ul li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 29.07px 186px 32.03px 94.97px;
        font-family: 'helvetica, sans-serif';
        font-style: normal;
        font-weight: 350;
        font-size: 14px;
        line-height: 160%; }
        @media screen and (max-width: 862px) {
          .pricing-sass .pricing_feature-comp > ul li {
            padding: 29.07px 106px 32.03px 44.97px; } }
        @media screen and (max-width: 580px) {
          .pricing-sass .pricing_feature-comp > ul li {
            padding: 29.07px 40px 32.03px 40px; } }
        @media screen and (max-width: 432px) {
          .pricing-sass .pricing_feature-comp > ul li {
            padding: 29.07px 20px 32.03px 20px; } }
        .pricing-sass .pricing_feature-comp > ul li p {
          color: #000000; }
        .pricing-sass .pricing_feature-comp > ul li:not(:last-child) {
          border-bottom: 1px solid rgba(146, 141, 170, 0.4); }
        .pricing-sass .pricing_feature-comp > ul li span {
          max-width: 58.692px;
          width: 100%;
          color: #05C786; }
